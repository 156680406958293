import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-external-layout',
  template: `
    <div class="external min-h-full">
      <div class="container mx-auto px-4 py-28">
        <router-outlet></router-outlet>
      </div>
    </div>
  `,
  styles: [
  ]
})
export class ExternalLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
