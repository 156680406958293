import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {map} from "rxjs";
import {AuthService, JwtHelperService} from "@tbg/ui-components/support";

@Component({
  selector: 'app-connect',
  template: ``,
  styles: [
  ]
})
export class ConnectComponent implements OnInit{

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private jwtHelper: JwtHelperService,
    private auth: AuthService
  ) {}

  ngOnInit() {
    this.activatedRoute.queryParams.pipe(
      map(params => params['token']),
      map( rawToken => this.auth.wrapInTokenData(rawToken)),
      this.auth.storeTokenAndUserData()
    )
      .subscribe({
        next: userData => this.router.navigate([userData.startRedirect]),
        error: () => console.error("Cannot log in ")
      });
  }

}
