import { Injectable } from '@angular/core';
import {map, Observable} from "rxjs";
import {Router} from "@angular/router";
import {SessionData} from "@tbg/ui-components";
import {AuthService, UserStorageService} from "@tbg/ui-components/support";

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  constructor(
    private userStorage: UserStorageService,
    private authService: AuthService,
    private router: Router
  ) {}

  get sessionData$(): Observable<SessionData> {
    return this.userStorage.data$.pipe(
      map(data => ({username: data.username} as SessionData))
    )
  }

  logout(){
    this.authService.logout();
    this.router.navigate(['/login/login']);
  }
}
