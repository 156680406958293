import {Component, OnInit} from '@angular/core';
import {
  ChatMsgConfig,
  ListOfLinksConfig,
  SectionTitleConfig,
  TableDataConfig
} from "@tbg/ui-components";
import {ActivatedRoute} from "@angular/router";
import {OverviewData, OverviewDataService} from "../overview-data.service";

@Component({
  selector: 'app-overview',
  template: `
    <div class="card bg-[#fafafa]">
      <lib-element-wrapper component="page-title" [config]="title"></lib-element-wrapper>
      <div class="card-body">
        <lib-element-wrapper class="w-4/5 mx-auto" component="chat-system-msg"
                             [config]="chatMsgConfig"></lib-element-wrapper>
        <ng-container *ngIf="!overviewData.hideStatistics">
          <lib-element-wrapper component="section-title" [config]="sectionTitleConf1"></lib-element-wrapper>
          <lib-element-wrapper class="w-4/5 mx-auto" component="table-data"
                               [config]="tableDataConfig"></lib-element-wrapper>
        </ng-container>
        <lib-element-wrapper component="section-title" [config]="section2"></lib-element-wrapper>
        <lib-element-wrapper class="w-4/5 mx-auto" component="list-of-links"
                             [config]="listOfLinksConfig"></lib-element-wrapper>
      </div>
      <lib-element-wrapper class="" component="navigate-to" [config]="navigateToConfig"></lib-element-wrapper>
    </div>
  `,
  styles: [
  ]
})
export class OverviewComponent implements OnInit {

  title!: SectionTitleConfig;
  section2!: SectionTitleConfig;
  listOfLinksConfig!: ListOfLinksConfig;
  tableDataConfig!: TableDataConfig;
  chatMsgConfig!: ChatMsgConfig;
  overviewData!: OverviewData;

  constructor(
    private route: ActivatedRoute,
    private dataService: OverviewDataService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe( params => {
      const areaKey = params['area'];
      const data = this.dataService.getData(areaKey);
      this.setData(data);
    });
  }

  private setData(data: OverviewData | undefined){
    if(data){
      this.overviewData = data;
      this.title = data.title;
      this.listOfLinksConfig = data.links;
      this.chatMsgConfig = data.node;
      this.tableDataConfig = data.table;
      this.section2 = data.section;
      console.log(this.chatMsgConfig);
    }
  }

  sectionTitleConf1 = {
    title: "Statistiche di area"
  }

  sectionTitleConf2 = {
    title: "Attività da gestire"
  }

  navigateToConfig = {
    link: "/home",
    content: "Torna alla dashboard",
    img: "https://tbg-resources.s3.eu-south-1.amazonaws.com/img/sdgs/icon-back.png",
    style: "flex gap-2"
  };

}
