import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {RemoteService} from "./remote.service";
import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {ExternalLayoutComponent} from './external-layout/external-layout.component';
import {InternalLayoutComponent} from './internal-layout/internal-layout.component';
import {LoginComponent} from './login/login.component';
import {MainComponent} from './main/main.component';
import {HomeComponent} from './home/home.component';
import {REMOTE_DATA_PROVIDER, SESSION_DATA_PROVIDER, UiComponentsModule} from "@tbg/ui-components";
import {TreeComponent} from './tree/tree.component';
import {OverviewComponent} from './overview/overview.component';
import {GeneralComponent} from './general/general.component';
import {ConfirmComponent} from './confirm/confirm.component';
import {IntroComponent} from './intro/intro.component';
import {SdgsOverviewComponent} from './sdgs-overview/sdgs-overview.component';
import {ScreenContainerComponent} from './screen-test/screen-container.component';
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {SessionService} from "./session.service";
import {InternalScreenLayoutComponent} from './internal-screen-layout/internal-screen-layout.component';
import {ConnectComponent} from './connect/connect.component';
import {RouteReuseStrategy} from "@angular/router";
import {CustomRouteReuseStrategy} from "./custom-route-reuse-strategy";
import localeIt from '@angular/common/locales/it';
import {registerLocaleData, DecimalPipe} from "@angular/common";
import {
  AUTH_PROVIDER,
  AuthInterceptor, AuthService, LangInterceptor,
  TokenStorageService,
  UserStorageService
} from "@tbg/ui-components/support";

registerLocaleData(localeIt);

@NgModule({
  declarations: [
    AppComponent,
    ExternalLayoutComponent,
    InternalLayoutComponent,
    LoginComponent,
    MainComponent,
    HomeComponent,
    TreeComponent,
    OverviewComponent,
    GeneralComponent,
    ConfirmComponent,
    IntroComponent,
    SdgsOverviewComponent,
    ScreenContainerComponent,
    InternalScreenLayoutComponent,
    ConnectComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatSnackBarModule,
    UiComponentsModule
  ],
  providers: [
    DecimalPipe,
    {
      provide: UserStorageService,
      useFactory: () => {
        return new UserStorageService(sessionStorage)
      }
    },
    {
      provide: TokenStorageService,
      useFactory: () => {
        return new TokenStorageService(sessionStorage)
      }
    },
    { provide: AUTH_PROVIDER, useClass: AuthService },
    { provide: REMOTE_DATA_PROVIDER, useClass: RemoteService },
    { provide: SESSION_DATA_PROVIDER, useClass: SessionService },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LangInterceptor, multi: true },
    // { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
