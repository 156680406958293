import {Component, OnInit} from '@angular/core';
import {ImageComponentConfig, SessionEndMsgConfig, TextContentConfig, WrappableChartConfig} from "@tbg/ui-components";

@Component({
  selector: 'app-sdgs-overview',
  template: `
    <div class="card bg-[#fafafa]">
      <lib-element-wrapper component="page-title" [config]="pageTitleConf"></lib-element-wrapper>
      <lib-element-wrapper component="session-end-msg" [config]="sessionEndMsg"></lib-element-wrapper>
      <lib-element-wrapper component="image" [config]="imageConfSDGsLogo"></lib-element-wrapper>
      <lib-element-wrapper component="text-content" [config]="contentConfig"></lib-element-wrapper>

      <div class="card-body">
        <lib-element-wrapper class="mt-4" component="section-title" [config]="sectionTitleConf1"></lib-element-wrapper>
        <div>
          <lib-element-wrapper class="" component="wrappable-chart" [config]="chartConfig2"></lib-element-wrapper>

          <div class="flex justify-evenly py-2">
            <lib-element-wrapper component="image" [config]="imageConfSDGs1"></lib-element-wrapper>
            <lib-element-wrapper component="image" [config]="imageConfSDGs2"></lib-element-wrapper>
            <lib-element-wrapper component="image" [config]="imageConfSDGs3"></lib-element-wrapper>
            <lib-element-wrapper component="image" [config]="imageConfSDGs4"></lib-element-wrapper>
            <lib-element-wrapper component="image" [config]="imageConfSDGs5"></lib-element-wrapper>
            <lib-element-wrapper component="image" [config]="imageConfSDGs6"></lib-element-wrapper>
            <lib-element-wrapper component="image" [config]="imageConfSDGs7"></lib-element-wrapper>
            <lib-element-wrapper component="image" [config]="imageConfSDGs8"></lib-element-wrapper>
            <lib-element-wrapper component="image" [config]="imageConfSDGs9"></lib-element-wrapper>
            <lib-element-wrapper component="image" [config]="imageConfSDGs10"></lib-element-wrapper>
            <lib-element-wrapper component="image" [config]="imageConfSDGs11"></lib-element-wrapper>
            <lib-element-wrapper component="image" [config]="imageConfSDGs12"></lib-element-wrapper>
            <lib-element-wrapper component="image" [config]="imageConfSDGs13"></lib-element-wrapper>
            <lib-element-wrapper component="image" [config]="imageConfSDGs14"></lib-element-wrapper>
            <lib-element-wrapper component="image" [config]="imageConfSDGs15"></lib-element-wrapper>
            <lib-element-wrapper component="image" [config]="imageConfSDGs16"></lib-element-wrapper>
            <lib-element-wrapper component="image" [config]="imageConfSDGs17"></lib-element-wrapper>

        </div>
        <lib-element-wrapper class="mt-4" component="section-title" [config]="sectionTitleConf2"></lib-element-wrapper>
        <div class="flex bg-gray-100 w-full p-8">
          <div class="flex-none my-auto mr-4">
            <img class="w-48" src="https://tbg-resources.s3.eu-south-1.amazonaws.com/img/sdgs/sdgs-wheel.png">
          </div>
          <div class="flex-1">
            <h3 class="font-semibold text-center mb-2">17 OBIETTIVI PER TRASFORMARE IL NOSTRO MONDO</h3>
            <p class="text-center">
              Gli Obiettivi per lo Sviluppo danno seguito ai risultati degli Obiettivi di Sviluppo del Millennio
              (Millennium Development Goals) che li hanno preceduti, e rappresentano obiettivi comuni su un insieme di
              questioni importanti per lo sviluppo: la lotta alla povertà, l’eliminazione della fame e il contrasto al
              cambiamento climatico, per citarne solo alcuni.
              ‘Obiettivi comuni’ significa che essi riguardano tutti i Paesi e tutti gli individui: nessuno ne è
              escluso, né deve essere lasciato indietro lungo il cammino necessario per portare il mondo sulla strada
              della sostenibilità.
            </p>
            <p class="text-center mt-2">
              <a target="_blank" href="https://www.un.org/sustainabledevelopment/sustainable-development-goals/"
                 class="btn btn-primary mx-auto">Approfondisci!</a>
            </p>
          </div>
        </div>
      </div>
        <lib-element-wrapper component="text-content" [config]="contentConfig2"></lib-element-wrapper>
      <lib-element-wrapper class="" component="navigate-to" [config]="navigateToConfig"></lib-element-wrapper>
    </div>
  `,
  styles: [
  ]
})
export class SdgsOverviewComponent implements OnInit {


  constructor() { }

  ngOnInit(): void {
  }

  chartConfig2 = {
    key: "794f8ee8-6235-49cc-9eba-7f42bcba7e28",
    title : {
      content: "",
      style: ""
    },
    titleClass: "text-secondary",
    options: {
      backgroundColor: ["#eb1d2e", "#d4a028", "#279b48", "#c31f33", "#ef402b", "#00aed9", "#fdb713", "#8f1838", "#f36d25", "#e11484", "#f99d26", "#cf8d2a", "#48773e", "#007dbc", "#3eb049", "#02558b", "#183668"],
      fill: true,
      plugins: {
        legend: {
          display: false
        }
      },
      scales:{
        y: {
            min: 0,
            max: 500
        }
      }
    },
    type: 'bar'
  } as WrappableChartConfig;

  imageConfSDGsLogo = {
    img: "https://tbg-resources.s3.eu-south-1.amazonaws.com/img/sdgs/sdgs-logo-header.png",
    style: "mx-auto w-6/12 mt-4",
    content: "sdgs-logo"
  } as ImageComponentConfig;

  imageConfSDGs1 = {
    img:"https://tbg-resources.s3.eu-south-1.amazonaws.com/img/sdgs/sdgs-goal-01.png",
    content:"sdg-01",
    link: "https://www.un.org/sustainabledevelopment/poverty/",
    style: "w-16"
  } as ImageComponentConfig

  imageConfSDGs2 = {
    img:"https://tbg-resources.s3.eu-south-1.amazonaws.com/img/sdgs/sdgs-goal-02.png",
    content:"sdg-03",
    link: "https://www.un.org/sustainabledevelopment/hunger/",
    style: "w-16"
  } as ImageComponentConfig

  imageConfSDGs3 = {
    img:"https://tbg-resources.s3.eu-south-1.amazonaws.com/img/sdgs/sdgs-goal-03.png",
    content:"sdg-03",
    link: "https://www.un.org/sustainabledevelopment/health/",
    style: "w-16"
  } as ImageComponentConfig

  imageConfSDGs4 = {
    img:"https://tbg-resources.s3.eu-south-1.amazonaws.com/img/sdgs/sdgs-goal-04.png",
    content:"sdg-04",
    link: "https://www.un.org/sustainabledevelopment/education/",
    style: "w-16"
  } as ImageComponentConfig

  imageConfSDGs5 = {
    img:"https://tbg-resources.s3.eu-south-1.amazonaws.com/img/sdgs/sdgs-goal-05.png",
    content:"sdg-05",
    link: "https://www.un.org/sustainabledevelopment/gender-equality/",
    style: "w-16"
  } as ImageComponentConfig

  imageConfSDGs6 = {
    img:"https://tbg-resources.s3.eu-south-1.amazonaws.com/img/sdgs/sdgs-goal-06.png",
    content:"sdg-05",
    link: "https://www.un.org/sustainabledevelopment/water-and-sanitation/",
    style: "w-16"
  } as ImageComponentConfig

  imageConfSDGs7 = {
    img:"https://tbg-resources.s3.eu-south-1.amazonaws.com/img/sdgs/sdgs-goal-07.png",
    content:"sdg-07",
    link: "https://www.un.org/sustainabledevelopment/energy/",
    style: "w-16"
  } as ImageComponentConfig

  imageConfSDGs8 = {
    img:"https://tbg-resources.s3.eu-south-1.amazonaws.com/img/sdgs/sdgs-goal-08.png",
    content:"sdg-08",
    link: "https://www.un.org/sustainabledevelopment/economic-growth/",
    style: "w-16"
  } as ImageComponentConfig

  imageConfSDGs9 = {
    img:"https://tbg-resources.s3.eu-south-1.amazonaws.com/img/sdgs/sdgs-goal-09.png",
    content:"sdg-09",
    link: "https://www.un.org/sustainabledevelopment/infrastructure-industrialization/",
    style: "w-16"
  } as ImageComponentConfig

  imageConfSDGs10 = {
    img:"https://tbg-resources.s3.eu-south-1.amazonaws.com/img/sdgs/sdgs-goal-10.png",
    content:"sdg-10",
    link: "https://www.un.org/sustainabledevelopment/inequality/",
    style: "w-16"
  } as ImageComponentConfig

  imageConfSDGs11 = {
    img:"https://tbg-resources.s3.eu-south-1.amazonaws.com/img/sdgs/sdgs-goal-11.png",
    content:"sdg-11",
    link: "https://www.un.org/sustainabledevelopment/cities/",
    style: "w-16"
  } as ImageComponentConfig

  imageConfSDGs12 = {
    img:"https://tbg-resources.s3.eu-south-1.amazonaws.com/img/sdgs/sdgs-goal-12.png",
    content:"sdg-12",
    link: "https://www.un.org/sustainabledevelopment/sustainable-consumption-production/",
    style: "w-16"
  } as ImageComponentConfig

  imageConfSDGs13 = {
    img:"https://tbg-resources.s3.eu-south-1.amazonaws.com/img/sdgs/sdgs-goal-13.png",
    content:"sdg-13",
    link: "https://www.un.org/sustainabledevelopment/climate-change/",
    style: "w-16"
  } as ImageComponentConfig

  imageConfSDGs14 = {
    img:"https://tbg-resources.s3.eu-south-1.amazonaws.com/img/sdgs/sdgs-goal-14.png",
    content:"sdg-14",
    link: "https://www.un.org/sustainabledevelopment/oceans/",
    style: "w-16"
  } as ImageComponentConfig

  imageConfSDGs15 = {
    img:"https://tbg-resources.s3.eu-south-1.amazonaws.com/img/sdgs/sdgs-goal-15.png",
    content:"sdg-15",
    link: "https://www.un.org/sustainabledevelopment/biodiversity/",
    style: "w-16"
  } as ImageComponentConfig

  imageConfSDGs16 = {
    img:"https://tbg-resources.s3.eu-south-1.amazonaws.com/img/sdgs/sdgs-goal-16.png",
    content:"sdg-16",
    link: "https://www.un.org/sustainabledevelopment/peace-justice/",
    style: "w-16"
  } as ImageComponentConfig

  imageConfSDGs17 = {
    img:"https://tbg-resources.s3.eu-south-1.amazonaws.com/img/sdgs/sdgs-goal-17.png",
    content:"sdg-17",
    link: "https://www.un.org/sustainabledevelopment/globalpartnerships/",
    style: "w-16"
  } as ImageComponentConfig


  sessionEndMsg = {
    img: "https://tbg-resources.s3.eu-south-1.amazonaws.com/img/sdgs/celebration-image.png",
    title: "Complimenti!",
    content: "Hai terminato con successo il Development Goals Business Game! Scorri in basso per scoprire che impatto hanno avuto le tue decisioni sul Sustainable Development Goals.",
    style: "card w-1/2 mx-auto mt-2"
  } as SessionEndMsgConfig

  contentConfig = {
    content: `<p class="text-center mt-4 mx-4">
    Il 25 settembre 2015, le Nazioni Unite hanno approvato l’Agenda 2030 per lo sviluppo sostenibile, un piano di
  azione globale sottoscritto da 193 Paesi delle Nazioni unite, tra cui l'Italia, per condividere l'impegno a
  garantire un presente e un futuro migliore al nostro Pianeta e alle persone che lo abitano.
  L'Agenda definisce 17 Obiettivi di sviluppo sostenibile (Sustainable Development Goals) da raggiungere entro
  il 2030 e per la prima volta viene espresso un chiaro giudizio sull’insostenibilità dell’attuale modello di
  sviluppo, non solo sul piano ambientale, ma anche su quello economico e sociale, superando in questo modo
  definitivamente l’idea che la sostenibilità sia unicamente una questione ambientale e affermando una visione
  integrata delle diverse dimensioni dello sviluppo.
</p>`,
    style: "w-11/12 mx-auto py-8"
  } as TextContentConfig;

  contentConfig2 ={
    content: `<h3 class="font-semibold text-center"> Per visualizzare nuovamente questa pagina, vai all'area <a href="/overview/communications">Comunicazioni</a></h3>`,
    style: ""
  } as TextContentConfig;


  pageTitleConf = {
    title: "Syntron e gli SDGs"
  }

  sectionTitleConf1 = {
    title: "Statistiche di gioco legate agli SDGS"

  };

  sectionTitleConf2 = {
    title: "Approfondimenti"
  };


  navigateToConfig = {
    link: "/home",
    content: "Torna alla dashboard",
    img: "https://tbg-resources.s3.eu-south-1.amazonaws.com/img/sdgs/icon-back.png",
    style: "flex gap-2"
  };


}
