import {Component, OnInit} from '@angular/core';
import {
  ElementConfig,
  IndicatorConfig, NavigateToConfig,
  SectionTitleConfig,
  TableDataConfig,
  WrappableChartConfig
} from "@tbg/ui-components";

@Component({
  selector: 'app-general',
  template: `
    <div class="card bg-[#fafafa]">
      <lib-element-wrapper  component="page-title"  [config]="pageTitleConf"></lib-element-wrapper>
      <div class="card-body">
        <div class="w-1/2 flex mx-auto my-6 space-x-4 ">
          <div class="flex-auto my-auto">
            <img src="https://tbg-resources.s3.eu-south-1.amazonaws.com/img/sdgs/logo-syntron-extended.svg">
          </div>
          <div class="mx-auto -ml-0.5 w-0.5 bg-gray-600"></div>
          <lib-element-wrapper class="flex-auto" component="hero-banner-value" [config]="heroComponentConfig"></lib-element-wrapper>
        </div>
        <lib-element-wrapper class="w-2/3 mx-auto" component="indicator-card" [config]="cardConfig0"></lib-element-wrapper>
        <lib-element-wrapper  class="mt-4" component="section-title"  [config]="sectionTitleConf1"></lib-element-wrapper>
        <div class="w-11/12 mx-auto grid grid-cols-3 gap-4">
          <lib-element-wrapper class="w-full" component="indicator-card" [config]="cardConfig1"></lib-element-wrapper>
          <lib-element-wrapper class="w-full" component="indicator-card" [config]="cardConfig2"></lib-element-wrapper>
          <lib-element-wrapper class="w-full" component="indicator-card" [config]="cardConfig3"></lib-element-wrapper>
        </div>
        <lib-element-wrapper  class="mt-4" component="section-title"  [config]="sectionTitleConf2"></lib-element-wrapper>
        <div class="w-11/12 mx-auto grid grid-cols-3 gap-4">
          <lib-element-wrapper class="w-4/5 mx-auto" component="table-data" [config]="tableDataConfig1"></lib-element-wrapper>
          <lib-element-wrapper class="w-4/5 mx-auto" component="table-data" [config]="tableDataConfig2"></lib-element-wrapper>
          <lib-element-wrapper class="w-4/5 mx-auto" component="table-data" [config]="tableDataConfig3"></lib-element-wrapper>
          <lib-element-wrapper class="w-4/5 mx-auto" component="table-data" [config]="tableDataConfig4"></lib-element-wrapper>
          <lib-element-wrapper class="w-4/5 mx-auto" component="table-data" [config]="tableDataConfig5"></lib-element-wrapper>
          <lib-element-wrapper class="w-4/5 mx-auto" component="table-data" [config]="tableDataConfig6"></lib-element-wrapper>
        </div>
        <lib-element-wrapper  class="mt-4" component="section-title"  [config]="sectionTitleConf3"></lib-element-wrapper>
        <div class="flex w-11/12 mx-auto gap-4">
          <lib-element-wrapper class="flex-1" component="wrappable-chart" [config]="chartConfig1"></lib-element-wrapper>
          <lib-element-wrapper class="flex-1" component="wrappable-chart" [config]="chartConfig2"></lib-element-wrapper>
        </div>
      </div>
      <div class="bg-gray-100 p-4">
        <h3 class="font-semibold text-center my-4">PENSI DI ESSERE STATO UN BRAVO MANAGER?</h3>
        <p class="text-center">
          Dai un occhio alla classifica globale e confronta il tuo punteggio con quello degli altri giocatori!
          Non sei soddisfatto? Beh, non ti resta che rigiocare!
        </p>
        <p class="text-center my-2">
          <a href="https://server.game.developmentgoals.thebusinessgame.it/ranking?sessionId=0" target="_blank" class="mx-auto btn btn-primary">Guarda la classifica!</a>
        </p>
      </div>
      <lib-element-wrapper component="navigate-to" [config]="navigateToConfig"></lib-element-wrapper>
    </div>
  `,
  styles: [
  ]
})
export class GeneralComponent implements OnInit {


  constructor() { }

  ngOnInit(): void {
  }

  cardConfig0 = {
    key: '54541e4c-28d1-42b8-bf9e-69ebf66e80a4',
    title:"Percentuale raggiungimento degli obiettivi aziendali",
    percentage: true,
    bars: true,
    medium: 33,
    high: 66,
    img: ""
  } as IndicatorConfig

  cardConfig1 = {
    key: 'b9bb9ea0-05d6-4dd9-b6fe-87ded8a3d1e9',
    title:"Datore di lavoro responsabile",
    img: "https://tbg-resources.s3.eu-south-1.amazonaws.com/img/sdgs/results-card-icon-1.png",
    percentage: true,
    bars: true,
    medium: 33,
    high: 66,
  } as IndicatorConfig

  cardConfig2 = {
    key: '457e8607-8058-4e98-b9c3-7480ba440632',
    title :"Business equo",
    img: "https://tbg-resources.s3.eu-south-1.amazonaws.com/img/sdgs/results-card-icon-2.png",
    percentage: true,
    bars: true,
    medium: 33,
    high: 66,
  } as IndicatorConfig

  cardConfig3 = {
    key: '35174583-8c37-4c6d-8136-29ea0be75511',
    title:"Eco-efficienza in produzione",
    img: "https://tbg-resources.s3.eu-south-1.amazonaws.com/img/sdgs/results-card-icon-3.png",
    percentage: true,
    bars: true,
    medium: 33,
    high: 66,
  } as IndicatorConfig

  tableDataConfig1 = {
    key: "6be02489-e254-462b-aff6-a2313fd76cf6",
    title: {
      content: "Risorse umane",
      style: "",
      background: "#0FAE92"
    },
    onlyLastColumn: true,
    caption: "Approfondisci le statistiche di area"
  } as TableDataConfig

  tableDataConfig2 = {
    key: "5418dcca-3fa6-4f20-b900-faf10e97327c",
    title: {
      content: "Produzione",
      style: "",
      background: "#F3C263",
    },
    onlyLastColumn: true,
    caption: "Approfondisci le statistiche di area"
  } as TableDataConfig

  tableDataConfig3 = {
    key: "eaa63435-e793-4f71-a336-ef68606f9a9f",
    title: {
      content: "Marketing",
      style: "",
      background: "#EC9A86"
    },
    onlyLastColumn: true,
    caption: "Approfondisci le statistiche di area"
  } as TableDataConfig

  tableDataConfig4 = {
    key: "88293abd-7e6b-4f11-bfd7-e22c5156d306",
    title: {
      content: "Amministrazione",
      style: "",
      background: "#9AC5E5"
    },
    onlyLastColumn: true,
    caption: "Approfondisci le statistiche di area"
  } as TableDataConfig

  tableDataConfig5 = {
    key: "397bf69e-d11e-4db0-9524-a91beb75b6c7",
    title: {
      content: "Finanza",
      style: "",
      background: "#AE7CE1"
    },
    onlyLastColumn: true,
    caption: "Approfondisci le statistiche di area"
  } as TableDataConfig

  tableDataConfig6 = {
    key: "1de06056-2d38-41c7-afc8-f3fb4ca5e7f8",
    title: {
      content: "Acquisti",
      style: "",
      background: "#A0ECD0"
    },
    onlyLastColumn: true,
    caption: "Approfondisci le statistiche di area"
  } as TableDataConfig

  heroComponentConfig = {
    key: '78f67981-1abe-484c-be67-b1b571e2b52a'
  } as ElementConfig;


  chartConfig1 = {
    key: "bc80d9e2-d3c5-4859-8141-52e6efe1ca12",
    title: {
      content: "Utile",
      style: ""
    },
    titleClass: "text-secondary",
    options: {
      pointRadius: 0,
      backgroundColor: '#8EBFE7',
      borderColor:'#8EBFE7',
      pointBorderColor:'#8EBFE7',
      pointBackgroundColor:'#8EBFE7',
      plugins: {
        legend: {
          display: true
        }
      }
    },
    type: 'line'
  } as WrappableChartConfig;

  chartConfig2 = {
    key: "c42226a1-7815-4cea-8ca4-8aba275049f5",
    title: {
      content: "Indicatori di prestazione",
      style: ""
    },
    titleClass: "text-secondary",
    options: {
      borderColor: ['#27C3ED', '#B94CE3', '#F08633'],
      pointRadius: 0,
      backgroundColor: ['#27C3ED', '#B94CE3', '#F08633'],
      plugins: {
        legend: {
          display: true
        },
      }
    },
    type: 'line'
  } as WrappableChartConfig;


  pageTitleConf = {
    title: "Analisi andamento aziendale"
  } as SectionTitleConfig;

  sectionTitleConf1 = {
    title: "Indicatori di prestazione complessivi"
  } as SectionTitleConfig;

  sectionTitleConf2 = {
    title: "Statistiche per aree"
  } as SectionTitleConfig;

  sectionTitleConf3 = {
    title: "Trend aziendale"
  } as SectionTitleConfig;

  navigateToConfig = {
    link: "/home",
    content: "Torna alla dashboard",
    img: "https://tbg-resources.s3.eu-south-1.amazonaws.com/img/sdgs/icon-back.png",
    style: "flex gap-2"
  } as NavigateToConfig;

}
