<div class="card bg-[#fafafa]">
  <lib-element-wrapper  component="page-title"  [config]="titleConf"></lib-element-wrapper>
  <div class="card-body">
    <lib-element-wrapper class="my-4" component="section-title"  [config]="section1Conf"></lib-element-wrapper>
    <div class="flex">
      <div class="flex-none">
        <img class="w-32" src="https://tbg-resources.s3.eu-south-1.amazonaws.com/img/sdgs/intro-beatrice-welcome.png" alt="Beatrice">
      </div>
      <div class="flex-1 w-64 my-auto ml-4">
        <h3 class="font-semibold text-lg mb-2">Benvenuto!</h3>
        <p>
          In questa simulazione vestirai i panni del nuovo Amministratore Delegato di SYNTRON SpA, un’azienda manifatturiera che si occupa di realizzare lavorazioni meccaniche di precisione per conto terzi, più precisamente lavorazioni di tornitura e fresatura.
        </p>
        <p>
          Io mi chiamo Beatrice e sono la responsabile della Segreteria Direzionale. Sarò il tuo braccio destro, nonché una preziosa guida all’interno del gioco!
        </p>

      </div>

    </div>
    <lib-element-wrapper  class="my-4" component="section-title"  [config]="section2Conf"></lib-element-wrapper>
    <p>
      Durante il gioco potrai prendere diverse decisioni all’interno delle varie funzioni aziendali. Per accedere alle specifiche di ogni singola funzione, clicca sull’apposita scheda-personaggio!
    </p>
    <div class="flex w-4/5 justify-around mx-auto">

      <img src="https://tbg-resources.s3.eu-south-1.amazonaws.com/img/sdgs/characters_badge-HR.svg" alt="hr">
      <img src="https://tbg-resources.s3.eu-south-1.amazonaws.com/img/sdgs/characters_badge-production.svg" alt="production">
      <img src="https://tbg-resources.s3.eu-south-1.amazonaws.com/img/sdgs/characters_badge-marketing.svg" alt="marketing">
      <img src="https://tbg-resources.s3.eu-south-1.amazonaws.com/img/sdgs/characters_badge-admin.svg" alt="admin">
      <img src="https://tbg-resources.s3.eu-south-1.amazonaws.com/img/sdgs/characters_badge-finance.svg" alt="finance">
      <img src="https://tbg-resources.s3.eu-south-1.amazonaws.com/img/sdgs/characters_badge-sales.svg" alt="sales">

    </div>
    <lib-element-wrapper  class="my-4" component="section-title"  [config]="section3Conf"></lib-element-wrapper>
    <div class="flex">
      <div class="flex-1 w-64 my-auto ml-4">
        <p class="mb-2">
          Entrando nella funzione aziendale, il responsabile ti fornirà le informazioni di sintesi sullo stato della funzione stessa e ti presenterà le situazioni che richiedono la tua attenzione.
        </p>
        <p>
          Cliccando su una specifica attività riceverai dal responsabile tutti i dettagli necessari per poterla gestire al meglio. Dopo aver ottenuto tutte le informazioni potrai decidere se gestirla o meno e in che modo.
        </p>
      </div>

      <div class="flex-none">
        <img class="w-48" src="https://tbg-resources.s3.eu-south-1.amazonaws.com/img/sdgs/intro-fulvia-check.gif" alt="Fulvia">
      </div>
    </div>
    <lib-element-wrapper  class="my-4" component="section-title"  [config]="section4Conf"></lib-element-wrapper>
    <div class="flex">
      <div class="flex-none">
        <img class="w-16" src="https://tbg-resources.s3.eu-south-1.amazonaws.com/img/sdgs/results-button.svg">
      </div>
      <div class="flex-1 w-64 my-auto ml-4">
        <p>
          Nella sezione “Analisi andamento aziendale” della Dashboard, invece, potrai vedere gli impatti delle tue decisioni sull’intera azienda.
        </p>
      </div>

    </div>
    <lib-element-wrapper  class="my-4" component="section-title"  [config]="section5Conf"></lib-element-wrapper>
    <p class="mb-4">
      Il gioco è strutturato su 3 livelli di difficoltà crescente, ognuno dei quali prevede 4 periodi di gioco e obiettivi differenti.
    </p>
    <h3 class="mx-auto font-semibold text-lg text-secondary">Direi che è tutto! Sei pronto?</h3>
    <a href="/home" class="btn btn-primary mx-auto mt-2">Si, cominciamo!</a>
  </div>
</div>
