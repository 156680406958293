/* PER ripristinare le modifiche all'ultima versione (buggata) del FE basta scommentare
il codice in questa classe e aggiornare la versione nel package.json "@tbg/ui-components": "^1.3.2",
 */

import {AfterViewInit, Component, OnInit, Type, ViewChild} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {
  ElementPlacementDirective,
  ElementSetupService, LayoutElement, PlaceholderComponent,
  ScreenComposerComponent,
  ScreenConfig, ScreenErrorMsgComponent, TextContentComponent, TextContentConfig
} from "@tbg/ui-components";

@Component({
  selector: 'app-screen-test',
  template: `
      <div class="card bg-[#fafafa]">
        <ng-template elementPlacement></ng-template>
    </div>
  `,
  styles: [
  ]
})
export class ScreenContainerComponent implements OnInit, AfterViewInit{

  config!: ScreenConfig;
  @ViewChild(ElementPlacementDirective, {static: true}) elementPlacement!: ElementPlacementDirective;

  constructor(
    private route: ActivatedRoute,
    private setupService: ElementSetupService
  ) {}

  screenConfig = {
    key: "screen-uuid-123",
  } as ScreenConfig;

  ngOnInit(): void {
    this.route.params.subscribe( params => {
      const key = params['key']
      this.config = this.createConfig(key);
    });
  }

  private createConfig(key: string): ScreenConfig{
    return {
      key: key
    }
  }

  loadComponent(){
    const viewContainerRef = this.elementPlacement.viewContainerRef;
    viewContainerRef.clear();
    const componentRef = viewContainerRef.createComponent<ScreenComposerComponent>(ScreenComposerComponent);
    this.setupService.setup(ScreenComposerComponent, componentRef, this.config)
      .subscribe({
          next: data => {},
          error: err => {
            console.error(`Error while loading for component ${componentRef.instance.name}: ${err}`)
            this.loadErrorMessageComponent();
          },
          complete: () => console.info(`%c Component ${componentRef.instance.name} loaded`, 'color:blue;')

        }
      )
    ;
  }

  ngAfterViewInit(): void {
    this.loadComponent();
  }

  loadErrorMessageComponent(){
    const componentClass = ScreenErrorMsgComponent;
    if(componentClass){
      const viewContainerRef = this.elementPlacement.viewContainerRef;
      viewContainerRef.clear();
      const componentRef = viewContainerRef.createComponent<LayoutElement>(componentClass);
      this.setupService.setup(componentClass, componentRef, this.config)
        .subscribe({
          next: () => console.log("Rendering the screen error msg component"),
          complete: () => this.setupService.componentOutputsSetup(componentRef, this.config)
        });
    }
  }

}
