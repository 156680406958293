import { Component, OnInit } from '@angular/core';
import {NavbarConfig} from "@tbg/ui-components";
import {SessionService} from "../session.service";

@Component({
  selector: 'app-internal-layout',
  template: `
    <div class="internal min-h-full">
      <div class="container mx-auto px-4">
        <lib-element-wrapper class="flex-auto" component="navbar" [config]="navbarConfig" (eventEmitter)="onLogout()"></lib-element-wrapper>
        <router-outlet></router-outlet>
        <footer class="footer items-center p-4 text-neutral-content">
          <p class="mx-auto">Copyright © 2022 - The Business Game</p>
        </footer>
      </div>
    </div>
  `,
  styles: [
  ]
})
export class InternalLayoutComponent implements OnInit {

  constructor(
    private session: SessionService
  ) { }

  ngOnInit(): void {
  }

  navbarConfig = {
    lang: ["ita"],
    key: "2b1f343e-b95d-48cd-bfa8-5aa1028ef44f",
    logo: {
      key: "",
      name: "",
      content: "Syntron Industries Management",
      img: "https://tbg-resources.s3.eu-south-1.amazonaws.com/img/sdgs/logo-syntron.svg",
      link: '/home',
      style: ""
    },
    logout:{
      key: "",
      name: "",
      content: "Logout",
      img: "https://tbg-resources.s3.eu-south-1.amazonaws.com/img/sdgs/navbar-logout.svg",
      link: 'login/login',
      style: ""
    },
    items: [
      {
        name: "navbar-item-indicator",
        key: 'level',
        content: "Livello",
        img: "https://tbg-resources.s3.eu-south-1.amazonaws.com/img/sdgs/navbar-icon-star.png",
        link: "",
        style: ""
      },
      {
        name: "navbar-item-indicator",
        key: 'period',
        content: "Periodo",
        img: "https://tbg-resources.s3.eu-south-1.amazonaws.com/img/sdgs/navbar-icon-calendar.png",
        link: "",
        style: ""
      },
    ],
  } as NavbarConfig;

  onLogout(){
    this.session.logout();
  }



}
