import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-intro',
  templateUrl: './intro.component.html',
  styles: [
  ]
})
export class IntroComponent implements OnInit {


  constructor() { }

  ngOnInit(): void {
  }

  titleConf = {
    title: "Comunicazioni"
  }

  section1Conf = {
    title: "Benvenuto in syntron industries management!"
  }

  section2Conf = {
    title: "La dashboard"
  }

  section3Conf = {
    title: "Le tue decisioni"
  }
  section4Conf = {
    title: "Gli impatti delle tue decisioni"
  }
  section5Conf = {
    title: "I periodi e i livelli"
  }

}
