import { Component } from '@angular/core';

@Component({
  selector: 'app-internal-screen-layout',
  template: `
    <div class="internal min-h-full">
      <div class="container mx-auto px-4">
        <router-outlet></router-outlet>
      </div>
    </div>
  `,
  styles: [
  ]
})
export class InternalScreenLayoutComponent {

}
