import {Component, OnInit} from '@angular/core';
import {ConfirmActionConfig, NavigateBackConfig, SectionTitleConfig} from "@tbg/ui-components";
import {RemoteService} from "../remote.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-confirm',
  template: `
    <div class="card bg-[#fafafa]">
      <lib-element-wrapper component="page-title" [config]="pageTitleConf"></lib-element-wrapper>
      <div class="card-body">
      <lib-element-wrapper class="" component="confirm-action" [config]="confirmActionConfig"></lib-element-wrapper>
      </div>
      <lib-element-wrapper class="" component="navigate-back" [config]="navigateBackConfig"></lib-element-wrapper>
    </div>
  `,
  styles: [
  ]
})
export class ConfirmComponent implements OnInit {

  buttonStyle = 'btn-primary';

  constructor(
    private remote: RemoteService,
    private router: Router
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }


  ngOnInit(): void {
  }


  pageTitleConf = {
    title : "Avanzamento di periodo"
  } as SectionTitleConfig;


  navigateBackConfig = {
    content: "Torna alla dashboard",
    style: "",
    img: "https://tbg-resources.s3.eu-south-1.amazonaws.com/img/sdgs/icon-back.png"
  } as NavigateBackConfig;

  confirmActionConfig = {
    key: "7cafa8cd-2852-4180-83da-221e6858a80e",
    button: {
      default: {
        content: "Save",
        style: "btn-primary"
      },
      sending: {
        content: "Sending..",
        style: "btn-primary"
      },
      success: {
        content: "Ok",
        style: "btn-success"
      },
      error: {
        content: "Error",
        style: "btn-error"
      }
    }
  } as ConfirmActionConfig;

}
