import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ExternalLayoutComponent} from "./external-layout/external-layout.component";
import {LoginComponent} from "./login/login.component";
import {InternalLayoutComponent} from "./internal-layout/internal-layout.component";
import {AuthGuard} from "./auth.guard";
import {HomeComponent} from "./home/home.component";
import {TreeComponent} from "./tree/tree.component";
import {OverviewComponent} from "./overview/overview.component";
import {GeneralComponent} from "./general/general.component";
import {ConfirmComponent} from "./confirm/confirm.component";
import {IntroComponent} from "./intro/intro.component";
import {SdgsOverviewComponent} from "./sdgs-overview/sdgs-overview.component";
import {ScreenContainerComponent} from "./screen-test/screen-container.component";
import {InternalScreenLayoutComponent} from "./internal-screen-layout/internal-screen-layout.component";
import {ConnectComponent} from "./connect/connect.component";

const routes: Routes = [
  { path: '',
    component: InternalLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: HomeComponent,
        data: { title: 'Home'}
      },
      {
        path: 'home',
        component: HomeComponent,
        data: { title: 'Home'}
      },
      {
        path: 'intro',
        component: IntroComponent,
        data: { title: 'Intro'}
      },
      {
        path: 'overview/:area',
        component: OverviewComponent,
        data: { title: 'Overview'}
      },
      {
        path: 'general',
        component: GeneralComponent,
        data: { title: 'General Overview'}
      },
      {
        path: 'tree/:key',
        component: TreeComponent,
        data: { title: 'Chat'}
      },
      {
        path: 'confirm',
        component: ConfirmComponent,
        data: { title: 'Confirm'}
      },
      {
        path: 'sdgs',
        component: SdgsOverviewComponent,
        data: { title: 'sdgs'}
      }
    ]
  },
  { path: '',
    component: InternalScreenLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'screen/:key',
        component: ScreenContainerComponent,
        data: {
          title: 'screen',
          noReuse: true
        }
      }
    ]
  },
  {
    path: 'login',
    component: ExternalLayoutComponent,
    children: [
      {
        path: 'login',
        component: LoginComponent
      },
      {
        path: 'connect',
        component: ConnectComponent
      }
    ]
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
