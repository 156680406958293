import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {Location} from "@angular/common";
import {ChatConfig} from "@tbg/ui-components/lib/chat/chat-config";

@Component({
  selector: 'app-tree',
  template: `
    <div class="card bg-[#fafafa]">
      <lib-element-wrapper  component="page-title"  [config]="pageTitleConf"></lib-element-wrapper>
      <div class="card-body">
        <lib-element-wrapper component="chat" [config]="chatConfig"></lib-element-wrapper>
      </div>
      <lib-element-wrapper class="" component="navigate-back" [config]="navigateBackConfig"></lib-element-wrapper>
    </div>
  `,
  styles: [
  ]
})
export class TreeComponent implements OnInit {

  chatConfig!: ChatConfig;

  constructor(
    private route: ActivatedRoute,
    private location: Location
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe( params => {
      const key = params['key'];
      this.chatConfig = this.createConfig(key);
    });
  }

  private createConfig(key: string){
    return {
      key: key,
      modal: {
        title: {
          content: "Sei sicuro di inviare?",
          style: ""
        },
        confirmButton: {
          content: "Invia",
          style: "btn-primary"
        },
        cancelButton: {
          content: "Annulla",
          style: "btn-ghost"
        },
        content: "Cliccando su <i>Invia</i> non potrai più modificare la tua decisione.",
      }
    } as ChatConfig

  }

  pageTitleConf = {
    title: "Chat"
  }

  navigateBackConfig = {
    content: "Torna all'area",
    img: "https://tbg-resources.s3.eu-south-1.amazonaws.com/img/sdgs/icon-back.png"
  }

  back(){
    this.location.back()
  }



}
