import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {first, map, Observable, take, tap} from 'rxjs';
import {AuthService} from "@tbg/ui-components/support";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {


  constructor(
    private router: Router,
    private authService: AuthService
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean>{
    return this.authService.isLoggedIn.pipe(
      // take(1),
      map((isLogged: boolean) => {
        if(!isLogged){
          this.router.navigate(['/login/login']);
          return false;
        }
        return true;
      })
    )
  }

}
