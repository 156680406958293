import {Injectable} from '@angular/core';
import {
  ChatMsgConfig,
  ListOfLinksConfig,
  SectionTitleConfig,
  TableDataConfig
} from "@tbg/ui-components";

@Injectable({
  providedIn: 'root'
})
export class OverviewDataService {

  private _data = new Map<String, OverviewData>();

  constructor() {
    const hr = this.createHR();
    const production = this.createProduction();
    const communications = this.createCommunications();
    const marketing = this.createMarketing();
    const finance = this.createFinance();
    const purchasing = this.createPurchasing();
    const admin = this.createAdministration();

    this._data.set("hr", hr);
    this._data.set("production", production);
    this._data.set("communications", communications);
    this._data.set("marketing", marketing);
    this._data.set("finance", finance);
    this._data.set("admin", admin);
    this._data.set("purchasing", purchasing);
  }

  getData(key: string){
    return this._data.get(key);
  }

  private createElementConfig(title: string): SectionTitleConfig {
    return {
      title: title
    }
  }

  private createListOfLinks(key: string): ListOfLinksConfig{
    return  {
      key: key,
      caption: "Le attività non concluse verranno riproposte successivamente"
    } as ListOfLinksConfig
  }

  private createChatNode(content: string, image: string): ChatMsgConfig {
    return {
      img: image,
      content: content,
      style: '',
      mirrored: false
    } as ChatMsgConfig;
  }

  private createTableData(key: string): TableDataConfig{
    return {
      key: key,
      headerBgColor: "#3e3e3e",
      headerColor: "#ffffff",
      style: "table-zebra",
      caption: ""
    } as TableDataConfig
  }


  private createData(title: string, listOfLinksKey: string, tableDataKey:string, nodeContent: string, nodeImage: string, section: string, hideStats: boolean){
    const titleConfig = this.createElementConfig(title);
    const listOfLinksConfig = this.createListOfLinks(listOfLinksKey);
    const chatNode = this.createChatNode(nodeContent, nodeImage);
    const tableData = this.createTableData(tableDataKey)
    const sectionTitle = this.createElementConfig(section)
    return  new OverviewData(titleConfig, chatNode, listOfLinksConfig, tableData, sectionTitle, hideStats);
  }

  private createHR(): OverviewData {
    return this.createData(
      "Area Risorse Umane",
      "3c9bd739-7fbc-439a-ba8d-86c118a1c318",
      "37518369-0d11-4933-81e3-def92744bfb4",
      "Ciao, sono Mario e sono il responsabile dell'Area Risorse Umane. Sono felice di collaborare con te e vorrei chiederti supporto nel prendere alcune decisioni importanti per migliorare e rendere efficiente la mia area.",
      "https://tbg-resources.s3.eu-south-1.amazonaws.com/img/sdgs/characters_chat-HR.png",
      "Attività da gestire",
      false
    );
  }


  private createProduction(): OverviewData {
    return this.createData(
      "Area Produzione",
      "47386d74-4ace-404a-9ed4-d66438b3465b",
      "6225aea7-a506-4e4f-8024-66b58d7c2393",
      "Ciao, sono Fulvia e sono la responsabile dell'Area Produzione. Sono felice di collaborare con te e vorrei chiederti supporto nel prendere alcune decisioni importanti per migliorare e rendere efficiente la mia area.",
      "https://tbg-resources.s3.eu-south-1.amazonaws.com/img/sdgs/characters_chat-production.png",
      "Attività da gestire",
      false
    );
  }

  private createCommunications(): OverviewData {
    return this.createData(
      "Area Comunicazioni",
      "df241653-dddf-4367-ac60-4db2e8f57c84",
      "e2e16c02-c330-4b65-842d-5bd391275044",
      "Ciao, sono Beatrice e sono la responsabile della Segreteria di Direzione. Sarò la tua guida in questo delicato percorso di gestione aziendale e il tuo punto di riferimento per le comunicazioni direzionali più importanti a livello strategico.",
      "https://tbg-resources.s3.eu-south-1.amazonaws.com/img/sdgs/characters_chat-communication.png",
      "Comunicazioni",
      true
    );
  }

  private createMarketing(): OverviewData {
    return this.createData(
      "Area Marketing",
      "900f66a6-cc50-4cba-bf9b-2816a4d56426",
      "6f894639-406c-49e0-b988-c309e8ac98d7",
      "Ciao, sono Jonathan e sono il responsabile dell'Area Marketing&Sales. Sono felice di collaborare con te e vorrei chiederti supporto nel prendere alcune decisioni importanti per migliorare e rendere efficiente la mia area.",
      "https://tbg-resources.s3.eu-south-1.amazonaws.com/img/sdgs/characters_chat-marketing.png",
      "Attività da gestire",
      false
    );
}

  private createFinance(): OverviewData {
    return this.createData(
      "Area Finanza",
      "2989d8aa-2326-4ba6-9b2a-8d7e41db84ed",
      "450d4f85-7c10-4f2c-a9bb-0b4951e3f2cb",
      "Ciao, sono Jonathan e sono il responsabile dell'Area Marketing&Sales. Sono felice di collaborare con te e vorrei chiederti supporto nel prendere alcune decisioni importanti per migliorare e rendere efficiente la mia area.",
      "https://tbg-resources.s3.eu-south-1.amazonaws.com/img/sdgs/characters_chat-finance.png",
      "Attività da gestire",
      false
    );
  }

  private createPurchasing(): OverviewData {
    return this.createData(
      "Area Acquisti",
      "a201face-d98e-4ec0-985d-42a56039fb4f",
      "a8f374a7-b67f-4802-b0ea-570ce06e9f18",
      "Ciao, sono Simona e sono la responsabile dell'Area Acquisti. Sono felice di collaborare con te e vorrei chiederti supporto nel prendere alcune decisioni importanti per migliorare e rendere efficiente la mia area.",
      "https://tbg-resources.s3.eu-south-1.amazonaws.com/img/sdgs/characters_chat-sales.png",
      "Attività da gestire",
      false
    );
  }

  private createAdministration(): OverviewData {
    return this.createData(
      "Area Amministrazione",
      "2eaec999-0be2-44db-8232-a4a0f3262359",
      "e1ff9c51-a4bf-46b3-9ddf-3bdaee15411c",
      "Ciao, sono Victoria e sono la responsabile dell'Area Amministrazione. Sono felice di collaborare con te e vorrei chiederti supporto nel prendere alcune decisioni importanti per migliorare e rendere efficiente la mia area.",
      "https://tbg-resources.s3.eu-south-1.amazonaws.com/img/sdgs/characters_chat-admin.png",
      "Attività da gestire",
      false
    );
  }

}

export class OverviewData {

  title: SectionTitleConfig;
  node: ChatMsgConfig;
  links: ListOfLinksConfig;
  table: TableDataConfig;
  section: SectionTitleConfig;
  hideStatistics: boolean;

  constructor(title: SectionTitleConfig, node:ChatMsgConfig, links: ListOfLinksConfig, table: TableDataConfig, section: SectionTitleConfig, hideStatistics: boolean) {
    this.title = title;
    this.node = node;
    this.links = links;
    this.table = table;
    this.section = section;
    this.hideStatistics = hideStatistics;
  }

}
