import {ActivatedRouteSnapshot, BaseRouteReuseStrategy} from "@angular/router";

export class CustomRouteReuseStrategy extends BaseRouteReuseStrategy {

  override shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    const noReuse = future.data['noReuse'];
    return noReuse ? false : super.shouldReuseRoute(future, curr);
  }

}
