import {Injectable} from '@angular/core';
import {catchError, Observable, of, throwError} from "rxjs";
import {HttpClient, HttpResponse} from "@angular/common/http";
import {environment} from "../environments/environment";
import {ElementCommand, PlayerDecision, RemoteData, RemoteDataProvider} from "@tbg/ui-components";

@Injectable({
  providedIn: 'root'
})
export class RemoteService implements  RemoteDataProvider {

  constructor(
    private http: HttpClient
  ) { }

  private elementUrl(element: string, uuid: string | undefined){
    return environment.apiUrl + '/element/' + element + '/' + uuid;
  }

  getData(element: string, uuid: string | undefined): Observable<any> {
    const url = this.elementUrl(element, uuid);
    return this.http.get<any>(url)
      .pipe(
        catchError(err => throwError(err))
      )
  }

  postData(element:string, uuid: string | undefined, decision: PlayerDecision): Observable<HttpResponse<ElementCommand>> {
    const url = this.elementUrl(element, uuid);
    return this.http.post<any>(url, decision, {observe: "response"});
  }

  unwrapData<Tdata>(data: RemoteData<Tdata>): Tdata {
    if(data.data){
      return data.data;
    } else {
      throw new Error("Remote data is empty");
    }
  }


}
