import {Component, OnInit} from '@angular/core';
import {ConfirmActionConfig, FormDataConverterService} from "@tbg/ui-components";
import {Router} from "@angular/router";
import {FormElementConfig} from "@tbg/ui-components/lib/form-element/form-element-config";
import {AuthService, LoginData} from "@tbg/ui-components/support";

@Component({
  selector: 'app-login',
  template: `
    <div class="mx-auto card w-96 bg-gray-100 shadow-xl">
      <div class="card-body">
        <div class="mx-auto">
          <img class="mx-auto pb-2" src="./assets/img/logo-tbg-login.svg">
          <h2 class="font-bold text-xl">TBG Simulations</h2>
        </div>
        <p *ngIf="errorMsg" class="mx-auto text-red-500 my-2 font-bold"> Wrong username or password</p>
        <lib-element-wrapper component="form-element" [config]="config"></lib-element-wrapper>
      </div>
    </div>
  `,
  styles: []
})
export class LoginComponent implements OnInit {

  config!: FormElementConfig;
  errorMsg = false;

  constructor(
    private dataConverter: FormDataConverterService,
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.confLogin();

    this.authService.isLoggedIn
      .subscribe({
        error: () => console.error("Cannot log in ")
      });

  }

  confLogin(){
    this.config = {
      key: "58eb502c-9d0b-4e5b-9b52-da6428ccd2cc",
      title: 'Login',
      style: "",
      nodes: [
        {
          key: "uuid-123",
          label: "Username",
          type: 'text',
          style: 'input-bordered input-primary',
          validators: {
            required: true
          }
        },
        {
          key: "uuid-456",
          label: "Password",
          type: 'password',
          style: 'input-bordered input-primary',
          validators: {
            required: true
          }
        },
      ],
      endpoint: '/login',
      showLabels: true,
      button : {
        default: {content: "login", style: "btn-primary btn-block"},
        success: {content: "", style: "btn-success btn-block"},
        sending: {content: "", style: "btn-primary btn-block"},
        error: {content: "", style: "btn-error btn-block"},
        disabled: {content: "login", style: "btn-primary btn-block"}
      }
    } as FormElementConfig;


  }

  onClick(){
    this.hideErrorMsg();
  }

  hideErrorMsg(){this.errorMsg = false};
  showErrorMsg(){this.errorMsg = true};

  confirmActionConfig = {
    key: "7cafa8cd-2852-4180-83da-221e6858a80e",
    button: {
      default: {
        content: "Save",
        style: "btn-primary"
      },
      sending: {
        content: "Sending..",
        style: "btn-primary"
      },
      success: {
        content: "Ok",
        style: "btn-success"
      },
      error: {
        content: "Error",
        style: "btn-error"
      }
    }
  } as ConfirmActionConfig;

}
