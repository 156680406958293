import {Component, OnInit} from '@angular/core';
import {ImageMapConfig} from "@tbg/ui-components/lib/image-map/image-map-config";

@Component({
  selector: 'app-home',
  template: `
    <div class="card bg-[#fafafa] h-fit">
      <lib-element-wrapper  component="image-map"  [config]="imageMapConfig"></lib-element-wrapper>
    </div>
  `,
  styles: [
  ]
})
export class HomeComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }


  imageMapConfig = {
    "navbar":  {
      "show": false,
      "title": ""
    },
    "sideBar": {
      "title": {
        "content": "",
        "style": ""
      },
      "content": "",
      "confirmButton": {
        "content": "Dettagli",
        "style": "btn-ghost"
      },
      "cancelButton": {
        "style": "btn-ghost",
        "content": "Chiudi"
      }
    },
    "zones": [
      {
        "id": 1,
        "title": "background",
        "content": "/",
        "position": {
          "top": 0,
          "left": 0
        },
        "img": "https://tbg-resources.s3.eu-south-1.amazonaws.com/img/sdgs/background-plant-3.png",
        "neutral": true
      },
      {
        "id": 2,
        "title": "logo-syntron",
        "content": "/",
        "position": {
          "top": 19.23,
          "left": 29.68
        },
        "img": "https://tbg-resources.s3.eu-south-1.amazonaws.com/img/sdgs/logo-syntron-extended.svg",
        "neutral": true
      },
      {
        "id": 3,
        "title": "Analisi andamento aziendale",
        "content": "<p>In questa sezione potrai trovare tutti i dati qualitativi e quantificavi che ti serviranno per prendere decisioni informate e valutare l'andamento di SYNTRON nei vari periodi.</p><p> Qui troverai: <ul class='list-disc'><li>Gli indicatori delle prestazioni generali</li><li>Le statistiche di ogni area</li> <li>Il trend di alcuni indicatori nei diversi periodi</li></ul>",
        "position": {
          "top": 25.83,
          "left": 265.99
        },
        "img": "https://tbg-resources.s3.eu-south-1.amazonaws.com/img/sdgs/results-button.svg",
        "link": "/general",
        "neutral": false
      },
      {
        "id": 4,
        "title": "Comunicazioni",
        "content": "<p><strong>Responsabile: </strong>Beatrice Rosa</p><p> In questa area troverai tutte le comunicazioni importanti che ti verranno mandate dalla direzione. Qui potrai avere informazioni importanti in merito agli obiettivi aziendali e alle attività su cui focalizzarti nel corso del tuo percorso.</p>",
        "position": {
          "top": 25.83,
          "left": 334.13
        },
        "img": "https://tbg-resources.s3.eu-south-1.amazonaws.com/img/sdgs/communication-button.svg",
        "link": "/overview/communications",
        "neutral": false
      },
      {
        "id": 5,
        "title": "Salva le tue decisioni",
        "content": "<p>In questa area potrai salvare le decisioni inserite e proseguire con i periodi di gioco!</p>",
        "position": {
          "top": 25.83,
          "left": 402.28
        },
        "img": "https://tbg-resources.s3.eu-south-1.amazonaws.com/img/sdgs/save-button.svg",
        "neutral": false,
        "link": "/confirm"
      },
      {
        "id": 6,
        "title": "Area Risorse Umane",
        "content": "<p><strong>Responsabile: </strong>Mario Rossi</p><p>L'Area Risorse Umane si occupa di tutte quelle attività di SYNTRON che riguardano la gestione e la valorizzazione del personale. Qui potrai prendere decisioni in merito alle assunzioni, ai salari, alla formazione e a tutte quelle attività che riguardano le persone in azienda.</p> <p>Per prendere le migliori decisioni ti verranno forniti:</p> <ul class='list-disc'><li>Dati quantitativi relativi a personale e stipendi</li> <li>Dati qualitativi sui risultati personali e aziendali</li> <li>Dati tecnici sulla composizione della forza lavoro</li> </ul>",
        "position": {
          "top": 107.27,
          "left": 51.66
        },
        "img": "https://tbg-resources.s3.eu-south-1.amazonaws.com/img/sdgs/characters_badge-HR.svg",
        "neutral": false,
        "link": "/overview/hr"
      },
      {
        "id": 7,
        "title": "Area Produzione",
        "content": "<p><strong>Responsabile: </strong>Fulvia Bianchi</p><p>L'Area Produzione si occupa di tutte quelle attività di SYNTRON che permettono di trasformare la materia prima in prodotti finiti. Qui potrai prendere decisioni in merito ai macchinari di produzione utilizzati, alla gestione degli scarti e a tutto ciò che concerne i processi di fresatura e tornitura.</p> <p>Per prendere le migliori decisioni ti verranno forniti:<p><ul class='list-disc'><li>Dati quantitativi su materie prime</li> <li>Dati qualitativi sui risultati dell'area</li> <li>Dati tecnici sui macchinari</li></ul>",
          "position": {
          "top": 107.27,
          "left": 205.45
        },
        "img": "https://tbg-resources.s3.eu-south-1.amazonaws.com/img/sdgs/characters_badge-production.svg",
        "neutral": false,
        "link": "/overview/production"
      },
      {
        "id": 8,
        "title": "Area Marketing",
        "content": "<p><strong>Responsabile: </strong>Jonathan Verdi</p><p>L'Area Marketing si occupa di tutte quelle attività che riguardano vendita dei prodotti e la promozione dell’azienda. Qui potrai prendere decisioni in merito alle campagne di comunicazione da intraprendere, la gestione della forza vendita, i rapporti con i clienti e a tutte quelle attività che riguardano la commercializzazione dei prodotti e la rappresentazione del marchio.</p> <p>Per prendere le migliori decisioni ti verranno forniti:</p> <ul class='list-disc'><li>Dati quantitativi relativi alla commercializzazione dei prodotti</li> <li>Dati qualitativi sul posizionamento del marchio</li> <li>Dati tecnici sulla gestione dei clienti</li></ul></p>",
        "position": {
          "top": 107.27,
          "left": 358.81
        },
        "img": "https://tbg-resources.s3.eu-south-1.amazonaws.com/img/sdgs/characters_badge-marketing.svg",
        "link": "/overview/marketing",
        "neutral": false,
      },
      {
        "id": 9,
        "title": "Area Amministrazione",
        "content": "<p><strong>Responsabile:</strong> Victoria Celeste</p><p>L'Area Amministrazione si occupa di tutte quelle attività di SYNTRON che riguardano una gestione trasversale delle attività. Qui potrai prendere decisioni che riguardano più funzioni e la gestione di risorse umane e finanziarie in modo trasversale.</p> <p>Per prendere le migliori decisioni ti verranno forniti:</p><ul class='list-disc'><li>Dati quantitativi relativi allo stato dell’azienda</li> <li>Dati qualitativi sui risultati delle risorse</li> <li>Dati tecnici sulla composizione aziendale</li></ul>",
        "position": {
          "top": 311.01,
          "left": 51.66
        },
        "img": "https://tbg-resources.s3.eu-south-1.amazonaws.com/img/sdgs/characters_badge-admin.svg",
        "link": "/overview/admin",
        "neutral": false,
      },
      {
        "id": 10,
        "title": "Area Finanza",
        "content": "<p><strong>Responsabile:</strong> Gianni Viola</p><p>L'Area Finanza si occupa di tutte quelle attività di SYNTRON che riguardano la gestione dei flussi economico finanziari. Qui potrai prendere decisioni in merito a budget, fondi, flussi di cassa e a tutte quelle attività che riguardano le finanze aziendali.</p> <p>Per prendere le migliori decisioni ti verranno forniti:</p> <ul class='list-disc'><li>Dati quantitativi relativi allo stato economico finanziario dell’azienda</li> <li>Dati qualitativi sui risultati economico-finanziari</li> <li>Dati tecnici sulla composizione delle risorse economico-finanziari</li></ul>",
        "position": {
          "top": 311.01,
          "left": 205.45
        },
        "img": "https://tbg-resources.s3.eu-south-1.amazonaws.com/img/sdgs/characters_badge-finance.svg",
        "neutral": false,
        "link": "/overview/finance"
      },
      {
        "id": 11,
        "title": "Area Acquisti",
        "content": "<p><strong>Responsabile:</strong>Simona Menta</p><p>L'Area Acquisti si occupa di tutte quelle attività di SYNTRON che riguardano la gestione dei flussi di risorse produttive in azienda. Qui potrai prendere decisioni in merito a tipo di materiali, qualità dei materiali, gestione dei magazzini e a tutte quelle attività che riguardano le finanze aziendali.</p> <p>Per prendere le migliori decisioni ti verranno forniti:</p> <ul class='list-disc'><li>Dati quantitativi relativi alle risorse in azienda</li> Dati qualitativi sui tipi di risorse produttive e la loro gestione</li><li>Dati tecnici sulla gestione dei magazzini</li></ul>",
        "position": {
          "top": 311.01,
          "left": 358.81
        },
        "img": "https://tbg-resources.s3.eu-south-1.amazonaws.com/img/sdgs/characters_badge-sales.svg",
        "neutral": false,
        "link": "/overview/purchasing"
      },
    ]
  } as ImageMapConfig;

}
